import React from "react";
import { SignUpFormContainer } from "../../components/Form/SignUpForm";
import Modal from "../../components/Modal";

interface SignUpModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSignInClick: () => void;
}

const SignUpModal: React.FC<SignUpModalProps> = ({
  isOpen,
  closeModal,
  onSignInClick,
}) => {
  const handleSubmit = (username: string, email: string, password: string) => {
    console.log("Sign up:", { username, email, password });
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className="w-full max-w-md">
      <SignUpFormContainer
        onSignIn={() => {
          closeModal();
          onSignInClick();
        }}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default SignUpModal;
