import React from "react";
import { DataUpIcon, Share } from "../../assets/icons";

const Invitation = () => {
  return (
    <div
      className="relative py-6 px-4 rounded-lg col-6"
      style={{
        backgroundImage: `url(/images/invitation.jpeg)`,
        backgroundPosition: "center top", // Focus on the top-center part of the image
        backgroundSize: "100% 100%", // Stretch the image height to 200% so the top 50% is visible
        backgroundRepeat: "no-repeat",
      }}
    >
      <img
        src="/PNGs/IVCartoon.png"
        alt=""
        className="absolute -top-8 -right-3 size-32 rtransform scale-x-[-1]"
      />
      <div className="col-4">
        <DataUpIcon />
        <span className="lt-xl-medium-white z-10">
          Invite your friends and family to join and earn rewards on their first
          purchase.
        </span>
      </div>
      <button className="row-3 h-10 rounded-lg py-[10px] px-4 bg-white place-content-center">
        <span className="satoshi-sm-bold-toggleBG-dark">Share Link</span>
        <Share />
      </button>
    </div>
  );
};

export default Invitation;
