interface activeProps {
  isActive: boolean;
}
export const ScrollLeft = ({ isActive }: activeProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-main-light dark:stroke-main-dark"
    >
      <g opacity={isActive ? "1" : "0.5"}>
        <path
          d="M12.5 15L7.5 10L12.5 5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export const ScrollRight = ({ isActive }: activeProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-main-light dark:stroke-main-dark"
    >
      <g opacity={isActive ? "1" : "0.5"}>
        <path
          d="M7.5 15L12.5 10L7.5 5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export const DataUpIcon = () => {
  return (
    <svg
      width="87"
      height="21"
      viewBox="0 0 87 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2733_37450)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.4287 0.0506113L12.7295 0.000976563C12.471 0.000976563 12.3418 0.311281 12.523 0.495241L16.7489 4.74786C17.0214 5.02276 17.3924 5.17722 17.7798 5.17722L33.0787 5.17722C33.3373 5.17722 33.4664 4.86553 33.2839 4.68296L28.8122 0.209928C28.7109 0.108576 28.5727 0.0502642 28.4297 0.0502642H28.4283L28.4287 0.0506113Z"
          fill="#039EF2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.8225 10.6418H33.8152V19.9398C33.8152 20.24 33.5719 20.4844 33.2706 20.4844H23.1833C22.9643 20.4844 22.8529 20.2195 23.0073 20.064L27.6209 15.3935H28.6532V10.6393H27.6449L28.6532 9.63068L30.5345 7.74838L31.2311 7.05176L34.8225 10.6428V10.6414V10.6418Z"
          fill="#039EF2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.16167 12.1914V5.0912H8.0981L18.3145 15.3076H0.515091C0.339113 15.3076 0.250256 15.5204 0.374517 15.6457L4.72085 19.992C5.002 20.2732 5.38485 20.4328 5.78262 20.4342L20.0719 20.4825C20.3912 20.4825 20.6991 20.3572 20.9257 20.1316L25.3127 15.7589C25.5254 15.5461 25.5254 15.2014 25.3127 14.9886L10.8523 0.525851C10.5156 0.18882 10.0592 0 9.58298 0L0.544594 0C0.242967 0 0 0.243314 0 0.544594V12.1924C0 12.4927 0.242967 12.737 0.544594 12.737H4.61846C4.9187 12.737 5.16305 12.4937 5.16305 12.1924L5.16167 12.1911V12.1914Z"
          fill="#039EF2"
        />
        <path
          d="M39.7818 16.918C38.7974 16.918 38.1602 16.3936 38.1602 15.2825V15.2166C38.1602 14.1055 38.8405 13.5811 39.8856 13.5811C40.2226 13.5811 40.5607 13.6189 40.8231 13.6977V14.1663C40.5367 14.0774 40.2556 14.0396 39.8995 14.0396C39.1115 14.0396 38.7415 14.4235 38.7415 15.2252V15.2669C38.7415 16.0725 39.0786 16.4578 39.7866 16.4578C40.0261 16.4578 40.2226 16.4439 40.401 16.4057V15.4686H39.5992L39.7158 15.0087H40.9813V16.7428C40.5267 16.8642 40.2226 16.9163 39.7818 16.9163V16.9177V16.918Z"
          fill="white"
        />
        <path
          d="M45.0212 13.6045H45.5978L46.9483 16.8905H46.311L46.0677 16.2532H44.554L44.3059 16.8905H43.6689L45.0233 13.6045H45.0219H45.0212ZM45.898 15.7985L45.3128 14.2744L44.7175 15.7985H45.8983H45.898Z"
          fill="white"
        />
        <path
          d="M50.3342 13.6094L51.483 16.1734L52.6267 13.6094H53.307V16.8905H52.7309V14.5837L51.7045 16.8905H51.2637L50.2377 14.5837V16.8905H49.6611V13.6094H50.3366H50.3342Z"
          fill="white"
        />
        <path
          d="M56.8363 13.6094V16.8905H56.2549V13.7552L56.8363 13.6094Z"
          fill="white"
        />
        <path
          d="M60.2721 13.6094L61.9684 15.9252V13.6094H62.5498V16.8905H62.0482L60.3558 14.5694V16.8905H59.7744V13.6094H60.2711H60.2721Z"
          fill="white"
        />
        <path
          d="M67.0113 16.918C66.0273 16.918 65.3896 16.3936 65.3896 15.2825V15.2166C65.3896 14.1055 66.07 13.5811 67.1151 13.5811C67.4521 13.5811 67.7902 13.6189 68.0526 13.6977V14.1663C67.7662 14.0774 67.4851 14.0396 67.1289 14.0396C66.341 14.0396 65.971 14.4235 65.971 15.2252V15.2669C65.971 16.0725 66.3091 16.4578 67.0161 16.4578C67.2556 16.4578 67.4521 16.4439 67.6305 16.4057V15.4686H66.8287L66.9467 15.0087H68.2122V16.7428C67.7572 16.8642 67.4535 16.9163 67.0127 16.9163L67.0113 16.9177V16.918Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.1736 3.56877L38.1602 3.56877V10.2434H44.1736C45.4009 10.2434 46.4058 9.24275 46.4058 8.01125V5.79956C46.4058 4.56563 45.3975 3.56738 44.1736 3.56738V3.56877ZM40.1237 5.53229H44.033C44.2586 5.53229 44.4409 5.71452 44.4409 5.94013V7.87207C44.4409 8.09629 44.2583 8.27991 44.033 8.27991H40.1237V5.53091V5.53229Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M52.073 3.56934L49.1744 3.56934L46.4053 10.244H48.5295L50.4843 5.53286V5.04276H50.7617V5.53286L52.7176 10.244H54.8432L52.073 3.56934Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.7842 3.56934V5.53286H56.9247V10.244H58.8882V5.53286H62.0294V3.56934L53.7842 3.56934Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M86.7162 5.29197C86.6197 4.88795 86.4159 4.51934 86.1209 4.22431C85.8245 3.92789 85.4559 3.72275 85.0519 3.62904C84.8873 3.58981 84.7148 3.56934 84.5437 3.56934L78.5303 3.56934V10.244H80.4938V8.67304H84.5437C84.6197 8.67304 84.6996 8.66783 84.7756 8.66158C84.8693 8.65256 84.963 8.63624 85.0519 8.61472C85.4559 8.52101 85.8245 8.31449 86.1209 8.01945C86.4159 7.72442 86.62 7.35546 86.7148 6.95144V6.94623L86.7172 6.93999C86.754 6.7765 86.7741 6.6092 86.7741 6.44086V5.79978C86.7741 5.62762 86.7526 5.45789 86.7158 5.29197H86.7162ZM80.4914 5.53286H84.4017C84.6274 5.53286 84.8096 5.71508 84.8096 5.9407V6.30306C84.8096 6.52729 84.627 6.70986 84.4017 6.70986H80.4924V5.53286H80.491H80.4914Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.8253 3.56934V3.76683L75.8228 8.28047H71.9149C71.6907 8.28047 71.5071 8.0979 71.5071 7.87402V3.56934H69.5449V8.34885C69.6182 8.83513 69.8528 9.29121 70.2086 9.63206C70.5647 9.97673 71.0308 10.1919 71.5223 10.2426H71.5324L75.306 10.244L75.8683 9.622V10.244H77.7888V3.56934H75.8267H75.8253Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M66.5877 3.56934L63.6895 3.56934L60.9189 10.244H63.0446L64.9994 5.53286V5.04276H65.2753V5.53286L67.2326 10.244H69.3569L66.5877 3.56934Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_37450">
          <rect width="86.774" height="20.485" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Share = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15833 11.744L12.85 15.0607M12.8417 5.91068L7.15833 9.22734M17.5 4.65234C17.5 6.03306 16.3807 7.15234 15 7.15234C13.6193 7.15234 12.5 6.03306 12.5 4.65234C12.5 3.27163 13.6193 2.15234 15 2.15234C16.3807 2.15234 17.5 3.27163 17.5 4.65234ZM7.5 10.4857C7.5 11.8664 6.38071 12.9857 5 12.9857C3.61929 12.9857 2.5 11.8664 2.5 10.4857C2.5 9.10497 3.61929 7.98568 5 7.98568C6.38071 7.98568 7.5 9.10497 7.5 10.4857ZM17.5 16.319C17.5 17.6997 16.3807 18.819 15 18.819C13.6193 18.819 12.5 17.6997 12.5 16.319C12.5 14.9383 13.6193 13.819 15 13.819C16.3807 13.819 17.5 14.9383 17.5 16.319Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const NavDivider = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-toggleBG-dark dark:stroke-white"
    >
      <path
        d="M4.5 9L7.5 6L4.5 3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AppleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.75 10C18.75 14.83 14.8344 18.75 10 18.75C5.16562 18.75 1.25 14.83 1.25 10C1.25 5.16562 5.16562 1.25 10 1.25C14.8344 1.25 18.75 5.16562 18.75 10Z" fill="#283544"/>
<path d="M14.1013 7.78587C14.0536 7.81372 12.9169 8.40155 12.9169 9.70491C12.9705 11.1913 14.3513 11.7126 14.375 11.7126C14.3513 11.7404 14.1665 12.4227 13.6192 13.1378C13.1848 13.7539 12.7026 14.375 11.9705 14.375C11.2741 14.375 11.0241 13.9644 10.2205 13.9644C9.35752 13.9644 9.11334 14.375 8.45262 14.375C7.72048 14.375 7.20262 13.7206 6.74455 13.1103C6.14944 12.3116 5.64361 11.0582 5.62575 9.85467C5.61372 9.21692 5.74493 8.59002 6.07801 8.05754C6.54812 7.31416 7.3874 6.80952 8.30394 6.79288C9.0062 6.77082 9.6312 7.24217 10.0598 7.24217C10.4705 7.24217 11.2383 6.79288 12.1071 6.79288C12.4821 6.79325 13.4821 6.89851 14.1013 7.78587ZM10.0004 6.66555C9.87538 6.08315 10.2205 5.50074 10.5419 5.12923C10.9526 4.67995 11.6013 4.375 12.1607 4.375C12.1964 4.9574 11.9701 5.5286 11.5656 5.9446C11.2026 6.39388 10.5776 6.73211 10.0004 6.66555Z" fill="white"/>
</svg>
);

export const CountryIcon= () => {
  return (
    <svg
      className="w-5 h-5  lg:w-6 lg:h-6"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2656_39839)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M-0.000488281 12.0006C-0.000488281 17.1603 3.25601 21.5587 7.82562 23.2543V0.74707C3.25601 2.44254 -0.000488281 6.8411 -0.000488281 12.0006Z"
          fill="#6DA544"
        />
        <path
          d="M23.9999 12.0006C23.9999 6.8411 20.7434 2.44254 16.1738 0.74707V23.2543C20.7434 21.5587 23.9999 17.1603 23.9999 12.0006Z"
          fill="#6DA544"
        />
      </g>
      <defs>
        <clipPath id="clip0_2656_39839">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

interface EyeIconProps {
  theme: "light" | "dark";
}
export const EyeIcon: React.FC<EyeIconProps> = ({ theme }) => {
  // Set the stroke color based on the theme
  const strokeColor = theme === "dark" ? "#FCFCFD" : "#667085";
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
}

export const FacebookIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10.333" cy="10" r="8.75" fill="url(#paint0_linear_2935_54061)"/>
<path d="M13.5916 12.676L13.9803 10.2063H11.5488V8.60437C11.5488 7.92856 11.8878 7.26942 12.9769 7.26942H14.083V5.16687C14.083 5.16687 13.0796 5 12.1207 5C10.1173 5 8.80905 6.18309 8.80905 8.32403V10.2063H6.58301V12.676H8.80905V18.6466C9.25595 18.715 9.71316 18.75 10.1789 18.75C10.6447 18.75 11.1019 18.715 11.5488 18.6466V12.676H13.5916Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_2935_54061" x1="10.333" y1="1.25" x2="10.333" y2="18.6981" gradientUnits="userSpaceOnUse">
<stop stop-color="#18ACFE"/>
<stop offset="1" stop-color="#0163E0"/>
</linearGradient>
</defs>
</svg>
);

export const GoogleIcon = () => (
  <svg 
    width="21" 
    height="20" 
    viewBox="0 0 21 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M19.4176 10.1941C19.4176 9.47471 19.358 8.94971 19.2291 8.40527H10.8462V11.6525H15.7668C15.6676 12.4594 15.1319 13.6747 13.9414 14.4913L13.9247 14.6L16.5752 16.6123L16.7589 16.6303C18.4454 15.1039 19.4176 12.858 19.4176 10.1941Z" 
      fill="#4285F4"
    />
    <path 
      d="M10.8453 18.75C13.256 18.75 15.2798 17.9722 16.758 16.6305L13.9405 14.4916C13.1866 15.0068 12.1747 15.3666 10.8453 15.3666C8.48424 15.3666 6.48029 13.8402 5.76594 11.7305L5.66123 11.7392L2.90518 13.8295L2.86914 13.9277C4.33737 16.786 7.35324 18.75 10.8453 18.75Z" 
      fill="#34A853"
    />
    <path 
      d="M5.76664 11.7307C5.57816 11.1863 5.46907 10.6029 5.46907 10.0002C5.46907 9.39734 5.57816 8.81403 5.75673 8.2696L5.75173 8.15365L2.96114 6.02979L2.86984 6.07235C2.26471 7.25847 1.91748 8.59044 1.91748 10.0002C1.91748 11.4099 2.26471 12.7418 2.86984 13.9279L5.76664 11.7307Z" 
      fill="#FBBC05"
    />
    <path 
      d="M10.8454 4.63331C12.5219 4.63331 13.6529 5.34303 14.2977 5.93612L16.8176 3.525C15.27 2.11528 13.2561 1.25 10.8454 1.25C7.35327 1.25 4.33738 3.21387 2.86914 6.07218L5.75604 8.26943C6.48031 6.15972 8.48427 4.63331 10.8454 4.63331Z" 
      fill="#EB4335"
    />
  </svg>
);

interface MessageIconProps {
  theme: "light" | "dark";
}
export const MessageIcon: React.FC<MessageIconProps> = ({ theme }) => {
  // Set the stroke color based on the theme
  const strokeColor = theme === "dark" ? "#FCFCFD" : "#667085";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 5.8335L8.4706 10.5964C9.02158 10.982 9.29707 11.1749 9.59672 11.2496C9.86142 11.3156 10.1383 11.3156 10.403 11.2496C10.7026 11.1749 10.9781 10.982 11.5291 10.5964L18.3332 5.8335M5.6665 16.6668H14.3332C15.7333 16.6668 16.4334 16.6668 16.9681 16.3943C17.4386 16.1547 17.821 15.7722 18.0607 15.3018C18.3332 14.767 18.3332 14.067 18.3332 12.6668V7.3335C18.3332 5.93336 18.3332 5.2333 18.0607 4.69852C17.821 4.22811 17.4386 3.84566 16.9681 3.60598C16.4334 3.3335 15.7333 3.3335 14.3332 3.3335H5.6665C4.26637 3.3335 3.56631 3.3335 3.03153 3.60598C2.56112 3.84566 2.17867 4.22811 1.93899 4.69852C1.6665 5.2333 1.6665 5.93336 1.6665 7.3335V12.6668C1.6665 14.067 1.6665 14.767 1.93899 15.3018C2.17867 15.7722 2.56112 16.1547 3.03153 16.3943C3.56631 16.6668 4.26637 16.6668 5.6665 16.6668Z"
        stroke={strokeColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SearchIcon = () => (
  <svg
    className="w-[15px] h-[15px]  lg:w-[18px] lg:h-[18px]"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7759 16.5L13.1509 12.875M15.1092 8.16667C15.1092 11.8486 12.1244 14.8333 8.44255 14.8333C4.76065 14.8333 1.77588 11.8486 1.77588 8.16667C1.77588 4.48477 4.76065 1.5 8.44255 1.5C12.1244 1.5 15.1092 4.48477 15.1092 8.16667Z"
      stroke="#475467"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface ShoppingBagIconProps {
  theme: "light" | "dark";
}
export const ShoppingBagIcon: React.FC<ShoppingBagIconProps> = ({ theme }) => {
  // Set the stroke color based on the theme
  const strokeColor = theme === "dark" ? "white" : "black";

  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6 2.20033L2.3 3.93366C2.04251 4.27697 1.91377 4.44863 1.91676 4.59232C1.91936 4.71736 1.97799 4.83462 2.07646 4.91172C2.18962 5.00032 2.40419 5.00032 2.83333 5.00032H15.1667C15.5958 5.00032 15.8104 5.00032 15.9235 4.91172C16.022 4.83462 16.0806 4.71736 16.0832 4.59232C16.0862 4.44863 15.9575 4.27697 15.7 3.93366L14.4 2.20033M3.6 2.20033C3.74667 2.00477 3.82 1.90699 3.91294 1.83647C3.99525 1.77401 4.08846 1.72741 4.18782 1.69903C4.3 1.66699 4.42222 1.66699 4.66667 1.66699H13.3333C13.5778 1.66699 13.7 1.66699 13.8122 1.69903C13.9115 1.72741 14.0047 1.77401 14.0871 1.83647C14.18 1.90699 14.2533 2.00477 14.4 2.20033M3.6 2.20033L2.03333 4.28921C1.83545 4.55306 1.73651 4.68498 1.66625 4.83026C1.6039 4.95917 1.55843 5.09559 1.53096 5.23612C1.5 5.3945 1.5 5.55941 1.5 5.88921L1.5 15.667C1.5 16.6004 1.5 17.0671 1.68166 17.4236C1.84144 17.7372 2.09641 17.9922 2.41002 18.152C2.76654 18.3337 3.23325 18.3337 4.16667 18.3337L13.8333 18.3337C14.7668 18.3337 15.2335 18.3337 15.59 18.152C15.9036 17.9922 16.1586 17.7372 16.3183 17.4236C16.5 17.0671 16.5 16.6004 16.5 15.667V5.88921C16.5 5.55941 16.5 5.3945 16.469 5.23613C16.4416 5.09559 16.3961 4.95918 16.3338 4.83026C16.2635 4.68498 16.1646 4.55306 15.9667 4.28921L14.4 2.20033M12.3333 8.33366C12.3333 9.21771 11.9821 10.0656 11.357 10.6907C10.7319 11.3158 9.88405 11.667 9 11.667C8.11594 11.667 7.2681 11.3158 6.64298 10.6907C6.01786 10.0656 5.66667 9.21771 5.66667 8.33366"
        stroke={strokeColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

interface SmileyIconProps {
  theme: "light" | "dark";
}
export const SmileyIcon: React.FC<SmileyIconProps> = ({ theme }) => {
  // Set the stroke color based on the theme
  const strokeColor = theme === "dark" ? "#FCFCFD" : "#000000";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2935_53928)">
        <path
          d="M6.66699 11.6665C6.66699 11.6665 7.91699 13.3332 10.0003 13.3332C12.0837 13.3332 13.3337 11.6665 13.3337 11.6665M12.5003 7.49984H12.5087M6.66699 7.49984H8.33366M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984ZM12.917 7.49984C12.917 7.72996 12.7304 7.9165 12.5003 7.9165C12.2702 7.9165 12.0837 7.72996 12.0837 7.49984C12.0837 7.26972 12.2702 7.08317 12.5003 7.08317C12.7304 7.08317 12.917 7.26972 12.917 7.49984Z"
          stroke={strokeColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2935_53928">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
