// redux/index.ts combines multiple slices (reducers) into a single root reducer. It serves as the primary reducer for the Redux store.

// handles all persisted state using redux-persist
import { combineReducers } from "redux";
import persistedAuthReducer from "../features/auth/store/authSlice";

const rootReducer = combineReducers({
  auth: persistedAuthReducer, //Handles authentication-related state using a persisted reducer
  // Add other reducers here as needed
});

export default rootReducer; //used in store.ts to configure the store.
