import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../features/auth/store/authSlice";
import { RootState, AppDispatch } from "../../redux/store";
import Navbar from "../../components/Navbar";
import { LoginFormContainer } from "../../components/Form/LoginForm";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error, user } = useSelector(
    (state: RootState) => state.persistedReducer.auth
  );

  const handleSubmit = (email: string, password: string) => {
    // Add your login logic here
    dispatch(login({ email, password }));
  };

  return (
    <div className="flex flex-col h-full bg-white dark:bg-black font-satoshi">
      <NavbarSection navigate={navigate} />
      <div className="flex flex-1 items-center justify-center">
        <div className="mx-8 w-[290px]  p-8 rounded-lg border border-gray-200 dark:border-gray-700">
          <LoginFormContainer
            onForgotPassword={() => navigate("/forgotPassword")}
            onSignUp={() => navigate("/signup")}
            onSubmit={handleSubmit}
            error={error}
            user={user}
          />
        </div>
      </div>
    </div>
  );
};

const NavbarSection = ({ navigate }: { navigate: (path: string) => void }) => (
  <>
    <div className="w-full mb-10">
      <Navbar
        onSignInClick={() => navigate("/signin")}
        onSignUpClick={() => navigate("/signup")}
      />
       <div className="border-b border-gray-200  dark:border-gray-700 block  dark:hidden"></div>
    </div>
   
  </>
);

export default SignIn;