import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { SignUpFormContainer } from "../../components/Form/SignUpForm";

const Signup = () => {
  const navigate = useNavigate();

  const handleSubmit = (username: string, email: string, password: string) => {
    // Add signup logic here
    console.log("Sign up:", { username, email, password });
  };

  return (
    <div className="flex flex-col h-full bg-white dark:bg-black font-satoshi">
      <div className="w-full mt-3 mb-10">
        <Navbar
          onSignInClick={() => navigate("/signin")}
          onSignUpClick={() => navigate("/signup")}
        />
        <div className="border-b border-gray-200  dark:border-gray-700 block mt-4 dark:hidden"></div>
      </div>

      <div className="flex flex-1 items-center justify-center mt-8">
        <div className="mx-4  w-[290px] p-8 rounded-lg border border-gray-200 dark:border-gray-700">
          <SignUpFormContainer
            onSignIn={() => navigate("/signin")}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
