import React, { useState } from "react";
import { Button } from "../Button";
import { GoogleIcon } from "../../assets/icons";
import { AppleIcon } from "../../assets/icons";
import { FacebookIcon } from "../../assets/icons";
import { MessageIcon } from "../../assets/icons";
import { EyeIcon } from "../../assets/icons";
import { FaEyeSlash } from "react-icons/fa";
import { useDarkMode } from "../../context/DarkModeContext";
import DataUpLogo from "../../assets/images/DataUpLogoAuth.svg";

// Shared interfaces
interface LoginFormContainerProps {
  onForgotPassword: () => void;
  onSignUp: () => void;
  onSubmit: (email: string, password: string) => void;
  error?: string | null;
  user?: any;
}

interface FooterLinksProps {
  onForgotPassword: () => void;
  onSignUp: () => void;
}

interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
  icon: React.ReactNode;
}

// Shared Login Form Container that includes all common elements
export const LoginFormContainer: React.FC<LoginFormContainerProps> = ({
  onForgotPassword,
  onSignUp,
  onSubmit,
  error,
  user,
}) => {
  const { darkMode, toggleDarkMode } = useDarkMode();
  return (
    <>
      <Logo />
      <Title />
      <div className="space-y-4">
        <SocialLoginButtons />
        <OrDivider />
        <LoginForm onSubmit={onSubmit} onForgotPassword={onForgotPassword} />
        <StatusMessages error={error} user={user} />
        <FooterLinks onForgotPassword={onForgotPassword} onSignUp={onSignUp} />
      </div>
    </>
  );
};

// Individual components that make up the login form
const Logo = () => (
  <div className="flex justify-center mb-4">
    <img src={DataUpLogo} alt="DatauplogoAuth" className="w-[135.55px] h-[32px]" />
  </div>
);

const Title = () => (
  <h2 className="text-2xl font-bold text-center mb-6 dark:text-white">
    Welcome back
  </h2>
);

const SocialLoginButtons = () => (
  <div className="flex gap-4 justify-center mb-4">
    <SocialButton icon={<GoogleIcon />} />
    <SocialButton icon={<AppleIcon />} />
    <SocialButton icon={<FacebookIcon />} />
  </div>
);

const SocialButton = ({ icon }: { icon: React.ReactNode }) => (
  <button className="p-2 border rounded-md w-32 flex items-center justify-center">
    {icon}
  </button>
);

const OrDivider = () => (
  <div className="flex items-center my-4">
    <div className="flex-1 border-t border-gray-300"></div>
    <div className="px-4 text-gray-500 text-sm dark:text-white">OR</div>
    <div className="flex-1 border-t border-gray-300"></div>
  </div>
);

interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;
}

const LoginForm = ({
  onSubmit,
  onForgotPassword,
}: LoginFormProps & { onForgotPassword: () => void }) => {
  const { darkMode } = useDarkMode();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <EmailInput
        email={email}
        setEmail={setEmail}
        icon={<MessageIcon theme={darkMode ? "dark" : "light"} />}
      />
      <div className="space-y-2">
        <PasswordInput
          password={password}
          setPassword={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
        <div className="text-right">
          <button
            type="button"
            onClick={onForgotPassword}
            className="text-xs font-bold dark:text-white  hover:underline"
          >
            Forgot Password?
          </button>
        </div>
      </div>
      <SubmitButton />
    </form>
  );
};
const EmailInput: React.FC<EmailInputProps> = ({ email, setEmail, icon }) => (
  <div className="relative">
    <input
      type="email"
      placeholder="Email address/Username"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full p-3 border rounded-lg pl-4 bg-white text-black dark:bg-black dark:text-white placeholder-gray-400 dark:placeholder-white"
      required
    />
    <div className="absolute top-1/2 right-3 transform -translate-y-1/2 dark:text-white text-gray-400">
      {icon}
    </div>
  </div>
);

const PasswordInput = ({
  password,
  setPassword,
  showPassword,
  setShowPassword,
}: {
  password: string;
  setPassword: (value: string) => void;
  showPassword: boolean;
  setShowPassword: (value: boolean) => void;
}) => {
  const { darkMode } = useDarkMode();

  return (
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-3 border rounded-lg pl-4 bg-white text-black dark:bg-black dark:text-white placeholder-gray-400 dark:placeholder-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        required
        aria-label="Password"
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer focus:outline-none"
        aria-label={showPassword ? "Hide password" : "Show password"}
      >
        {showPassword ? (
          <EyeIcon theme={darkMode ? "dark" : "light"} />
        ) : (
          <FaEyeSlash className="text-gray-400 dark:text-white" />
        )}
      </button>
    </div>
  );
};

const SubmitButton = () => (
  <Button
    type="submit"
    bgColor="bg-gradient-to-r from-[#027EC2] to-[#039EF2]"
    textColor="text-white"
    borderRadius="rounded-lg"
    className="w-full h-10"
  >
    Sign In
  </Button>
);

const StatusMessages = ({
  error,
  user,
}: {
  error?: string | null;
  user?: any;
}) => (
  <>
    {error && <p className="text-red-500">{error}</p>}
    {user && <p className="text-green-500">Logged In!</p>}
  </>
);

const FooterLinks = ({ onForgotPassword, onSignUp }: FooterLinksProps) => (
  <>
    <p className="text-sm md:text-md dark:text-white text-center">
      Don't have an account?{" "}
      <span className="text-[#039EF2] cursor-pointer" onClick={onSignUp}>
        Sign Up
      </span>
    </p>
  </>
);
