import React, { useState } from "react";
import { MessageIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useDarkMode } from "../../context/DarkModeContext";
import { Button } from "../Button";
// Types
interface ResetPasswordFormProps {
  isModal?: boolean;
  onClose?: () => void;
  onSignIn?: () => void;
}

// Main Component
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  isModal = false,
  onClose,
  onSignIn,
}) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Reset link sent to:", email);
    // Add your password reset logic here
  };

  const handleSignInClick = () => {
    if (isModal && onSignIn) {
      onSignIn();
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="space-y-4">
      <FormContent
        email={email}
        setEmail={setEmail}
        onSubmit={handleSubmit}
        onClose={onClose}
        darkMode={darkMode}
      />
    </div>
  );
};

// Form Components
interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
  darkMode: boolean;
}

const EmailInput: React.FC<EmailInputProps> = ({
  email,
  setEmail,
  darkMode,
}) => (
  <div className="relative">
    <input
      type="email"
      placeholder="Email address"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full p-3 border rounded-lg pl-4 bg-white text-black dark:bg-black dark:text-white placeholder-gray-400 dark:placeholder-white"
      required
    />
    <div className="absolute top-1/2 right-3 transform -translate-y-1/2 dark:text-white text-gray-400">
      <MessageIcon theme={darkMode ? "dark" : "light"} />
    </div>
  </div>
);

interface ActionButtonsProps {
  onClose?: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onClose }) => (
  <div className="flex gap-4">
    {onClose && (
      <Button
        onClick={onClose}
        bgColor="bg-gray-200 dark:bg-gray-800"
        className="flex-1"
        borderRadius="rounded-lg"
      >
        Cancel
      </Button>
    )}
    <Button
      type="submit"
      bgColor="bg-gradient-to-r from-[#027EC2] to-[#039EF2]"
      textColor="text-white"
      className="flex-1 h-10"
      borderRadius="rounded-lg"
    >
      Submit
    </Button>
  </div>
);

interface FormContentProps extends EmailInputProps {
  onSubmit: (e: React.FormEvent) => void;
  onClose?: () => void;
  darkMode: boolean;
}

const FormContent: React.FC<FormContentProps> = ({
  email,
  setEmail,
  onSubmit,
  onClose,
  darkMode,
}) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <EmailInput email={email} setEmail={setEmail} darkMode={darkMode} />
    <ActionButtons onClose={onClose} />
  </form>
);

export default ResetPasswordForm;
