import React,  { useState } from "react";
import { IoPersonCircle } from "react-icons/io5";
import { FaRegMoon } from "react-icons/fa";
import { ShoppingBagIcon } from "../../assets/icons";
import DataUpLogo from "../../assets/images/DataUpLogo.svg";
import Page from "../../assets/images/Page.svg";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { useDarkMode } from "../../context/DarkModeContext";
import { SearchIcon } from "../../assets/icons";
import { CountryIcon } from "../../assets/icons";


interface NavbarProps {
  onSignInClick: () => void;
  onSignUpClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onSignInClick, onSignUpClick }) => {
  const { darkMode, toggleDarkMode } = useDarkMode();
  
  return ( 
      <div className="w-screen font-satoshi px-[16px] lg:px-[56px]  bg-white dark:bg-black h-28 md:h-[80px] flex flex-col md:flex-row items-center justify-between py-4 md:py-4 lg:py-3 gap-2">
        <LogoSection toggleDarkMode={toggleDarkMode} darkMode={darkMode}/>
        <div className="w-full flex-1">
          <Search />
        </div>
        <AuthAndNavSection
          onSignInClick={onSignInClick}
          onSignUpClick={onSignUpClick}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
        />
      </div>
  );
};

const LogoSection = ({ toggleDarkMode, darkMode }:{ toggleDarkMode: () => void; darkMode: boolean }) => (
  <div className="flex items-center gap-2">
    <div className="flex gap-2">
      <Link to="/signin">
        <IoPersonCircle size={28} className="md:hidden text-black dark:text-white" />
      </Link>
      <img src={DataUpLogo} alt="dataUpLogo" className="w-20 md:w-32 block dark:hidden" />
      <img src={Page} alt="DarkModeLogo" className="w-20 md:w-32 hidden dark:block" />
    </div>
    <Button className="text-[10px] bg-[#F4F4F5] md:p-2 lg:p-0  lg:text-xs hidden md:block">
      Categories
    </Button>
    <div className="flex items-center gap-4 md:hidden">
    <IconWrapper src={<CountryIcon />} alt="Country" />
      <IconWrapper>
        <ShoppingBagIcon  theme={darkMode ? 'dark' : 'light'}/>
      </IconWrapper>
      <DarkModeToggle toggleDarkMode={toggleDarkMode} />
    </div>
  </div>
);

const Search = () => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="relative flex items-center max-w-full bp:max-w-2xl lg:max-w-full">
      <div
        className={`absolute left-1/2 -translate-x-1/2 flex items-center gap-2 pointer-events-none transition-opacity duration-200 ${
          inputValue ? "opacity-0" : "opacity-100"
        }`}
      >
        <SearchIcon />
        <span className="text-xs md:text-xs lg:text-sm  text-black dark:text-white whitespace-nowrap">
          What are you looking for?
        </span>
      </div>
      <input
        className="w-full h-[40px] bg-[#F4F4F5] dark:bg-gray-800 rounded-full focus:outline-none text-gray-600 dark:text-gray-300 text-center"
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

const AuthAndNavSection = ({
  onSignInClick,
  onSignUpClick,
  toggleDarkMode,
  darkMode
}: {
  onSignInClick: () => void;
  onSignUpClick: () => void;
  toggleDarkMode: () => void;
  darkMode: boolean
}) => (
  <div className="hidden md:flex items-center gap-4">
    <IconWrapper src={<CountryIcon />} alt="Country" />
    <div className="flex items-center gap-2 lg:gap-4">
      <Button
        className="text-[14px] md:p-2 lg:p-0  lg:text-xs xl:text-base"
        onClick={onSignInClick}
        bgColor="bg-[#F4F4F5] dark:bg-gray-800"
      >
        Sign In
      </Button>
      <Button
        className="text-[14px] md:p-2 lg:p-0 lg:text-xs xl:text-base"
        bgColor="bg-[#039EF2]"
        textColor="text-white"
        onClick={onSignUpClick}
      >
        Sign Up
      </Button>
    </div>
    <IconWrapper>
    <ShoppingBagIcon  theme={darkMode ? 'dark' : 'light'}/>
    </IconWrapper>
    <DarkModeToggle toggleDarkMode={toggleDarkMode} />
  </div>
);

const IconWrapper = ({ src, alt, children }: { src?: React.ReactNode; alt?: string; children?: React.ReactNode }) => (
  <div className="bg-[#F4F4F5] dark:bg-gray-800 p-2 lg:p-2 rounded-3xl">
    {src ? (
      typeof src === "string" ? (
        <img src={src} alt={alt} />
      ) : (
        src
      )
    ) : (
      children
    )}
  </div>
);

const DarkModeToggle = ({ toggleDarkMode }: { toggleDarkMode: () => void }) => (
  <button
    onClick={toggleDarkMode}
    className="p-2 text-black dark:text-white bg-[#F4F4F5] dark:bg-gray-800 rounded-full"
  >
    <FaRegMoon size={20} />
  </button>
);

export default Navbar;
