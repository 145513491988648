import React from "react";
import { LoginFormContainer } from "../Form/LoginForm";
import Modal from "../Modal";

interface LoginModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSignUpClick: () => void;
  onForgotPasswordClick: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen,
  closeModal,
  onSignUpClick,
  onForgotPasswordClick,
}) => {
  const handleSubmit = (email: string, password: string) => {
    console.log("Login attempt", { email, password });
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className="w-[90%] md:w-[400px]">
      <LoginFormContainer
        onForgotPassword={() => {
          closeModal();
          onForgotPasswordClick();
        }}
        onSignUp={() => {
          closeModal();
          onSignUpClick();
        }}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default LoginModal;
