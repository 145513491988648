import React from "react";
import { useNavigate } from "react-router-dom";
import DataUpLogo from "../../assets/images/DataUpLogoAuth.svg";
import Navbar from "../../components/Navbar";
import ResetPasswordForm from "../../components/Form/ResetPasswordForm";

// Types and Interfaces
interface ForgotPasswordProps {
  onClose?: () => void;
  onSignIn?: () => void;
}

interface AuthLayoutProps {
  children: React.ReactNode;
}

interface AuthCardProps {
  children: React.ReactNode;
}

interface AuthHeaderProps {
  title: string;
  description: string;
}

interface AuthFooterProps {
  onSignIn: () => void;
}

// Layout Components
const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => (
  <div className="flex flex-col h-screen bg-white dark:bg-black font-satoshi">
    {children}
  </div>
);

const AuthNavigation: React.FC<{
  onSignInClick: () => void;
  onSignUpClick: () => void;
}> = ({ onSignInClick, onSignUpClick }) => (
  <>
    <div className="w-full mt-3">
      <Navbar onSignInClick={onSignInClick} onSignUpClick={onSignUpClick} />
    </div>
    <div className="border-b border-gray-200 dark:border-gray-700 mt-4" />
  </>
);

const AuthCard: React.FC<AuthCardProps> = ({ children }) => (
  <div className="flex flex-1 items-center justify-center">
    <div className="mx-4 w-full max-w-md p-8 rounded-lg border border-gray-200 dark:border-gray-700">
      {children}
    </div>
  </div>
);

// Content Components
const BrandLogo: React.FC = () => (
  <div className="flex justify-center mb-4">
    <img src={DataUpLogo} alt="DataUp Gaming Logo" className="w-24" />
  </div>
);

const AuthHeader: React.FC<AuthHeaderProps> = ({ title, description }) => (
  <>
    <h2 className="text-xl font-bold text-center mb-2 dark:text-white">
      {title}
    </h2>
    <p className="text-center text-gray-500 mb-4 text-xs dark:text-white">
      {description}
    </p>
  </>
);

const AuthFooter: React.FC<AuthFooterProps> = ({ onSignIn }) => (
  <p className="text-center text-xs text-gray-500 dark:text-white mt-4">
    Remember your account details?{" "}
    <span
      className="text-[#039EF2] font-bold text-xs cursor-pointer"
      onClick={onSignIn}
    >
      Sign In
    </span>
  </p>
);

// Main Component
const ResetPassword: React.FC<ForgotPasswordProps> = ({ onClose, onSignIn }) => {
  const navigate = useNavigate();

  const handleNavigation = {
    signIn: () => navigate("/signin"),
    signUp: () => navigate("/signup"),
  };

  return (
    <AuthLayout>
      <AuthNavigation
        onSignInClick={handleNavigation.signIn}
        onSignUpClick={handleNavigation.signUp}
      />
      <AuthCard>
        <BrandLogo />
        <AuthHeader
          title="Reset password"
          description="A link to reset your password would be sent to your email."
        />
        <ResetPasswordForm
          isModal={false}
          onClose={onClose}
          onSignIn={onSignIn}
        />
        <AuthFooter onSignIn={handleNavigation.signIn} />
      </AuthCard>
    </AuthLayout>
  );
};

export default ResetPassword;





