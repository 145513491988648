import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login as loginAPI, LoginResponse } from "../api/auth";

interface AuthState {
  user: LoginResponse["data"] | null;
  token: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  loading: false,
  error: null,
};

// Async thunk for logging in
// createAsyncThunk: It allows you to define an action that returns a Promise (an asynchronous operation), and Redux Toolkit will automatically dispatch actions like pending, fulfilled, and rejected based on the promise state. Takes 2 args:
// arg1: A string representing the action type ('auth/login').
// arg2: An async function that handles the actual logic for this action. This async function can receive parameters (email, password) and will return the result or handle errors.
// <LoginResponse,any,{rejectValue: any}>:
// Param1: return type of the async function when successful
// param2: type of the arg passed to the async function
// param3: type of the error object that is returned if failure.
// could have been: <LoginResponse, { email: string; password: string }, { rejectValue: string }
export const login = createAsyncThunk<LoginResponse, any, { rejectValue: any }>(
  "auth/login", // Action type string (unique identifier for this action)
  async (
    { email, password }: { email: string; password: string },
    thunkAPI
  ) => {
    // The async function that performs the API request
    try {
      const response = await loginAPI(email, password);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message
          ? action.payload.message
          : "error connecting to server";
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
