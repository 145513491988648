import React, { useState } from "react";
import { leaderboardData } from "../../assets/DemoData";

const Leaderboard = () => {
  return (
    <div
      className="col-4 p-4 rounded-lg min-w-[288px]"
      style={{
        backgroundImage: `url(/images/LeaderBoardBG.jpeg)`,
        backgroundPosition: "center top", // Focus on the top-center part of the image
        backgroundSize: "400% 300%", // Stretch the image height to 200% so the top 50% is visible
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <Body />
    </div>
  );
};

export default Leaderboard;

const Header = () => {
  const [toggle, setToggle] = useState<string>("Monthly");
  return (
    <div className="col-4">
      <div className="col-2 place-items-center">
        <img src="/PNGs/crown.png" alt="" className="size-10" />
        <span className="lt-xl-semibold-main-dark">Leaderboard</span>
      </div>
      <div className="rounded-2xl p-[6px] row-2 bg-BG-light dark:bg-BG-dark">
        {["Monthly", "All Time"].map((item, index) => (
          <button
            key={index}
            className={`flex-1 py-[6px] px-[10px] place-content-center ${
              toggle === item
                ? "inter-xs-semibold-white rounded-2xl bg-toggleBG-light"
                : "inter-xs-medium-toggleBG-dark dark:text-main-dark"
            }`}
            onClick={() => setToggle(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

const Body = () => {
  leaderboardData.sort((a, b) => b.point - a.point);
  return (
    <div className="col-2">
      {leaderboardData.map((leaderboard, index) => (
        <div className="row-2 rounded-lg px-4 py-2 bg-BG-light dark:bg-BG-dark">
          <div className="row-2 items-center">
            <Position index={index} />
            <img
              className="size-11"
              src={leaderboard.path}
              alt={leaderboard.title}
            />
          </div>
          <div className="flex flex-col justify-center">
            <span className="inter-xs-bold-black dark:text-main-dark">
              {leaderboard.title}
            </span>
            <span className="inter-xs-bold-pry-600 text-[10px]">
              {leaderboard.point} points
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const Position = ({ index }: { index: number }) => {
  const commonClassses =
    "size-6 flex items-center justify-center rounded-full p-2";
  if (index < 3) {
    const lbColor = ["#FFBF00", "#C0C0C0", "#CD7F32"];
    const bgColor = lbColor[index];
    return (
      <span
        className={`${commonClassses} satoshi-sm-bold-white`}
        style={{ backgroundColor: bgColor }}
      >
        {index + 1}
      </span>
    );
  } else {
    return (
      <span
        className={`${commonClassses} satoshi-sm-bold-toggleBG-dark dark:text-white border border-border-light dark:border-x-border-dark`}
      >
        {index + 1}
      </span>
    );
  }
};
