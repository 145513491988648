import { ReactNode, ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  bgColor?: string;
  textColor?: string;
  className?: string;
  borderRadius?: string;
}

export const Button = ({ 
    children, 
    onClick, 
    bgColor = "bg-gray-200 dark:bg-gray-800",
    textColor = "text-black dark:text-white",
    className = "",
    borderRadius = "rounded-3xl",
    ...props
  }: ButtonProps) => {
    return (
      <button 
        onClick={onClick}
        className={`px-4 lg:px-4 lg:py-2 text-sm lg:text-base font-semibold  ${borderRadius} ${bgColor} ${textColor} ${className} transition-all duration-300`}
        {...props}
      >
        <p>{children}</p>
      </button>
    );
  };
