import React from "react";
import "./styles/global.scss"; // Import your global styles here
import AppRouter from "./routes/AppRouter";

function App() {
  return (
    <div className="bg-white dark:bg-black">
      <AppRouter />
    </div>
  );
}

export default App;
