import React, { useState } from "react";
import Navbar from "../components/Navbar";
// import Hero from "../components/Homepage/Hero";
import { DesktopMain, MobileMain } from "../components/Homepage/Main";
import Leaderboard from "../components/Homepage/Leaderboard";
import Invitation from "../components/Homepage/Invitation";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import { getCourse } from "../features/courseSlice";
import LoginModal from "../components/AuthModals/LoginModal";
import SignUpModal from "../components/AuthModals/SignUpModal";
import ForgotPasswordModal from "../components/AuthModals/ResetPasswordModal";
import Hero from "../components/Homepage/Hero";

const Homepage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error, courses } = useSelector(
    (state: RootState) => state.courses
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(getCourse());
  };
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const handleSignInClick = () => {
    setShowLoginModal(true); // Show Login Modal
  };

  const handleSignUpClick = () => {
    setShowSignUpModal(true); // Show SignUp Modal
  };

  const handleForgotPasswordClick = () => {
    setShowLoginModal(false); // Close Login Modal
    setShowForgotPasswordModal(true); // Show Forgot Password Modal
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false); // Close Login Modal
  };

  const handleCloseSignUpModal = () => {
    setShowSignUpModal(false); // Close SignUp Modal
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false); // Close Forgot Password Modal
  };

  const handleSignInFromSignUp = () => {
    setShowSignUpModal(false);
    setShowLoginModal(true);
  };

  return (
    <div>
      {/* <button onClick={handleSubmit}>Fetch courses</button>
      {courses.map((course) => (
        <div key={course.id}>{course.title}</div>
      ))} */}
      <Navbar
        onSignInClick={handleSignInClick}
        onSignUpClick={handleSignUpClick}
      />
      <Hero/>
      {/* <Hero /> */}
      <div className="bp:row-8 bp:px-14">
        <DesktopMain />
        <MobileMain />
        <div>
          {/* retain the top div to make sticky and avoid leaderboard from scroll */}
          <div className="hidden bp:sticky bp:top-20 bp:col-12 bp:pt-10">
            <Leaderboard />
            <Invitation />
          </div>
        </div>
      </div>
      
      {/* Render the LoginModal */}
      <LoginModal
        isOpen={showLoginModal}
        closeModal={handleCloseLoginModal}
        onSignUpClick={handleSignUpClick}
        onForgotPasswordClick={handleForgotPasswordClick}
      />

      {/* Render the SignUpModal */}
      <SignUpModal
        isOpen={showSignUpModal}
        closeModal={handleCloseSignUpModal}
        onSignInClick={handleSignInFromSignUp}
      />

      {/* Render the ForgotPasswordModal */}
      <ForgotPasswordModal
        isOpen={showForgotPasswordModal}
        onSignInClick={handleSignInClick}
        closeModal={handleCloseForgotPasswordModal}
      />
    </div>
  );
};

export default Homepage;
