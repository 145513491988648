import React, { useRef, useState } from "react";
import { ScrollLeft, ScrollRight } from "../../assets/icons";
import mainContent from "../../assets/DemoData";
import Leaderboard from "./Leaderboard";
import { useLocation } from "react-router-dom";

export const DesktopMain = () => {
  return (
    <div className="hidden bp:col-14 bp:pt-10 bp:pb-2 min-w-[300px]">
      {mainContent.map((data, index) => (
        <ScrollableSection data={data} key={index} />
      ))}
    </div>
  );
};

export const MobileMain = () => {
  const MostPopularToday = mainContent[0];
  return (
    <div className="col-6 px-4 pt-10 pb-2 bp:hidden">
      {/* Most Popular Comes first, followed by Leaderboard and then other categories */}
      <SectionContainer>
        <Header
          img={MostPopularToday.header.img}
          title={MostPopularToday.header.title}
          viewAll={MostPopularToday.header.viewAll}
        />
        <CardContainer cardData={MostPopularToday.card} />
      </SectionContainer>
      <Leaderboard />
      {mainContent.slice(1).map((data, index) => (
        <SectionContainer key={index + 1}>
          <Header
            img={data.header.img}
            title={data.header.title}
            viewAll={data.header.viewAll}
          />
          <CardContainer cardData={data.card} />
        </SectionContainer>
      ))}
    </div>
  );
};

// Scrollable Section Component
const ScrollableSection = ({ data }: { data: any }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollState, setScrollState] = useState({ left: false, right: true });

  const updateScrollState = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setScrollState({
        left: scrollLeft > 0,
        right: scrollLeft + clientWidth < scrollWidth,
      });
    }
  };

  const handleScroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300; // Adjust for scroll increment
      const newScrollPosition =
        direction === "left"
          ? scrollContainerRef.current.scrollLeft - scrollAmount
          : scrollContainerRef.current.scrollLeft + scrollAmount;

      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="col-4">
      <Header
        img={data.header.img}
        title={data.header.title}
        viewAll={data.header.viewAll}
        onScrollLeft={() => handleScroll("left")}
        onScrollRight={() => handleScroll("right")}
        scrollState={scrollState}
      />
      <div
        ref={scrollContainerRef}
        // whitespace-nowrap prevents the text within from wrapping
        className="overflow-x-auto whitespace-nowrap"
        style={{ scrollbarWidth: "none" }}
        onScroll={updateScrollState}
      >
        <CardContainer cardData={data.card} />
      </div>
    </div>
  );
};

type HeaderProps = {
  img: string;
  title: string;
  viewAll?: boolean;
  onScrollLeft?: () => void;
  onScrollRight?: () => void;
  scrollState?: { left: boolean; right: boolean };
};

export const Header = (props: HeaderProps) => {
  const pathname = useLocation().pathname === "/" ? true : false;
  return (
    <div className="row-1 justify-between">
      {/* icon and title */}
      <div className="row-2 items-center">
        <img src={props.img} alt={props.title} className="size-8 pb-[6px]" />
        <span className="lt-xl-semibold-black bp:satoshi-xl-bold-black dark:text-white">
          {props.title}
        </span>
      </div>
      {/* nav icons */}
      <div className="row-4 items-center">
        {pathname && (
          <div className="hidden bp:row-2">
            <NavBG
              icon={ScrollLeft}
              isActive={true}
              onClick={props.onScrollLeft}
            />
            <NavBG
              icon={ScrollRight}
              isActive={true}
              onClick={props.onScrollRight}
            />
          </div>
        )}
        {props.viewAll && pathname && (
          <a
            href="/view_all"
            className="satoshi-lg-medium-main-light dark:text-main-dark"
          >
            View all
          </a>
        )}
      </div>
    </div>
  );
};

type NavBGProps = {
  icon: React.ComponentType<{ isActive: boolean }>;
  isActive: boolean;
  onClick?: () => void;
};

const NavBG = ({ icon: Icon, isActive, onClick }: NavBGProps) => {
  return (
    <button
      onClick={onClick}
      disabled={!isActive}
      className="bg-navBG dark:bg-BG-dark flex justify-center items-center size-8 rounded-full"
    >
      <Icon isActive={isActive} />
    </button>
  );
};

interface SectionProps {
  children: React.ReactNode;
}

export const SectionContainer = ({ children }: SectionProps) => {
  // Applies only to the mobile screen
  return (
    <div className="col-4 p-4 rounded-lg bg-BG-light dark:bg-BG-dark bp:dark:bg-black bp:bg-white">
      {children}
    </div>
  );
};

type CardDataprop = {
  cardData: {
    title: string;
    discount: string;
    image: string;
  }[];
};

export const CardContainer = (props: CardDataprop) => {
  const pathname = useLocation().pathname === "/" ? true : false;
  return (
    <div
      className={`bp:row-4 bp:px-[10px] pb-[14px] grid grid-cols-2 gap-4 ${
        pathname ? "" : "flex-wrap"
      } `}
    >
      {props.cardData.map((card, index) => (
        <div key={index} className="col-1">
          <div
            className="relative bp:w-[194px] h-[164px] rounded-lg bg-cover bg-center"
            style={{ backgroundImage: `url(${card.image})` }}
          >
            <span className="absolute top-2 right-1 rounded-2xl px-2 py-[2px] bg-main-dark border border-white satoshi-xs-bold-discount text-center">
              {card.discount}
            </span>
          </div>
          <span className="py-1 px-2 satoshi-sm-medium-main-light dark:text-white">
            {card.title}
          </span>
        </div>
      ))}
    </div>
  );
};
