import React from "react";
import Modal from "../Modal";
import DataUpLogo from "../../assets/images/DataUpLogoAuth.svg";
import ResetPasswordForm from "../Form/ResetPasswordForm";

interface ForgotPasswordModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSignInClick: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  isOpen,
  closeModal,
  onSignInClick,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} className="h-[391px] w-[484px]">
      <div className="flex justify-center mb-4">
      <img src={DataUpLogo} alt="DatauplogoAuth" className="w-[135.55px] h-[32px]" />
      </div>
      <h2 className="text-2xl font-bold text-center mb-6 dark:text-white">
        Reset Password
      </h2>
      <p className="text-center text-sm text-gray-500 mb-4 dark:text-white">
        A link to reset your password will be sent to your email.
      </p>
      <ResetPasswordForm 
        isModal={true}
        onClose={closeModal}
        onSignIn={onSignInClick}
      />
      <p className="text-center text-gray-500 dark:text-white mt-4">
        Remember your account details?{" "}
        <span
          className="text-[#039EF2] cursor-pointer"
          onClick={() => {
            closeModal();
            onSignInClick();
          }}
        >
          Sign In
        </span>
      </p>
    </Modal>
  );
};

export default ForgotPasswordModal;
