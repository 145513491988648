import React from "react";
import Navbar from "../components/Navbar";
import { NavDivider } from "../assets/icons";
import { Header } from "../components/Homepage/Main";
import mainContent from "../assets/DemoData";
import { CardContainer, SectionContainer } from "../components/Homepage/Main";

const ViewAll: React.FC<{
  onSignInClick?: () => void; 
  onSignUpClick?: () => void;
}> = ({ onSignInClick, onSignUpClick }) => {
  const category = mainContent[2];

  return (
    <div className="col-6 px-4 pt-10 pb-2 bp:col-14 bp:pt-10 bp:pb-2 min-h-screen">
     <Navbar
        onSignInClick={onSignInClick || (() => {})} 
        onSignUpClick={onSignUpClick || (() => {})} 
      />
      <div className="px-14 pt-14 pb-2">
        <div className="row-1 gap-[2px] place-items-center">
          {/* Dynamic navigation links */}
          <Navigation title="Direct Top-up Product Details" />
          <NavDivider />
          <Navigation title="Call of Duty: Mobile" />
        </div>
      </div>
      <div>
        <SectionContainer>
          {category?.header && (
            <Header
              img={category.header.img}
              title={category.header.title}
              viewAll={category.header.viewAll}
            />
          )}
          {category?.card && <CardContainer cardData={category.card} />}
        </SectionContainer>
      </div>
    </div>
  );
};

export default ViewAll;

interface NavDividerProps {
  href?: string;
  title: string;
}

const Navigation: React.FC<NavDividerProps> = ({ href, title }) => (
  <a
    href={href || "#"} // Default to "#" if href is not provided
    className="satoshi-xs-bold-toggleBG-dark dark:text-white"
  >
    {title}
  </a>
);
