import React, { useState, useEffect, useRef } from "react";
import { EyeIcon } from "../../assets/icons";
import { MessageIcon } from "../../assets/icons";
import { SmileyIcon } from "../../assets/icons";
import { GoogleIcon } from "../../assets/icons";
import { AppleIcon } from "../../assets/icons";
import { FacebookIcon } from "../../assets/icons";
import { FaEyeSlash } from "react-icons/fa";
import DataUpLogo from "../../assets/images/DataUpLogoAuth.svg";
import { useDarkMode } from "../../context/DarkModeContext";
import { Button } from "../Button";

interface SignUpFormContainerProps {
  onSignIn: () => void;
  onSubmit: (username: string, email: string, password: string) => void;
  error?: string | null;
}

export const SignUpFormContainer: React.FC<SignUpFormContainerProps> = ({
  onSignIn,
  onSubmit,
  error,
}) => {
  const { darkMode, toggleDarkMode } = useDarkMode();
  return (
    <>
      <Logo />
      <Title />
      <div className="space-y-4">
        <SocialLoginButtons />
        <OrDivider />
        <SignUpForm onSubmit={onSubmit} />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <FooterLinks onSignIn={onSignIn} />
      </div>
    </>
  );
};

const Logo = () => (
  <div className="flex justify-center mb-4">
    <img src={DataUpLogo} alt="DatauplogoAuth" className="w-[135.55px] h-[32px]" />
  </div>
);

const Title = () => (
  <h2 className="text-xl font-bold text-center mb-6 dark:text-white">
    Create an account
  </h2>
);

const SocialLoginButtons = () => (
  <div className="flex gap-4 justify-center">
    <SocialButton icon={<GoogleIcon />} />
    <SocialButton icon={<AppleIcon />} />
    <SocialButton icon={<FacebookIcon />} />
  </div>
);

const SocialButton = ({ icon }: { icon: React.ReactNode }) => (
  <button className="p-2 border rounded-md w-32 flex items-center justify-center">
    {icon}
  </button>
);

const OrDivider = () => (
  <div className="flex items-center my-4">
    <div className="flex-1 border-t border-gray-300"></div>
    <div className="px-4 text-gray-500 text-sm dark:text-white">OR</div>
    <div className="flex-1 border-t border-gray-300"></div>
  </div>
);

interface SignUpFormProps {
  onSubmit: (username: string, email: string, password: string) => void;
}

const SignUpForm = ({ onSubmit }: SignUpFormProps) => {
  const { darkMode } = useDarkMode();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(username, email, password);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <FormInput
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        icon={<SmileyIcon theme={darkMode ? "dark" : "light"} />}
      />
      <FormInput
        type="email"
        placeholder="Email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        icon={<MessageIcon theme={darkMode ? "dark" : "light"} />}
      />
      <PasswordInput
        password={password}
        setPassword={setPassword}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
      <PasswordStrengthIndicator />
      <TermsAndConditions />
      <SubmitButton />
    </form>
  );
};

interface FormInputProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon: React.ReactNode;
}

const FormInput = ({
  type,
  placeholder,
  value,
  onChange,
  icon,
}: FormInputProps) => (
  <div className="relative">
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full p-3 border rounded-lg pl-4 bg-white text-black dark:bg-black dark:text-white placeholder-gray-400 dark:placeholder-white"
      required
    />
    <div className="absolute top-1/2 right-3 transform -translate-y-1/2 dark:text-white text-gray-400">
      {icon}
    </div>
  </div>
);

interface PasswordInputProps {
  password: string;
  setPassword: (value: string) => void;
  showPassword: boolean;
  setShowPassword: (value: boolean) => void;
}

const PasswordInput = ({
  password,
  setPassword,
  showPassword,
  setShowPassword,
}: {
  password: string;
  setPassword: (value: string) => void;
  showPassword: boolean;
  setShowPassword: (value: boolean) => void;
}) => {
  const { darkMode } = useDarkMode();

  return (
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-3 border rounded-lg pl-4 bg-white text-black dark:bg-black dark:text-white placeholder-gray-400 dark:placeholder-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        required
        aria-label="Password"
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer focus:outline-none"
        aria-label={showPassword ? "Hide password" : "Show password"}
      >
        {showPassword ? (
          <EyeIcon theme={darkMode ? "dark" : "light"} />
        ) : (
          <FaEyeSlash className="text-gray-400 dark:text-white" />
        )}
      </button>
    </div>
  );
};

const PasswordStrengthIndicator = () => (
  <>
    <p className="text-xs text-gray-500 dark:text-white">
      Must be at least 8 characters
    </p>
    <div className="w-full h-1 flex">
      <div className="flex-1 bg-orange-500 rounded-l-full"></div>
      <div className="flex-1 bg-gray-200 ml-0.5"></div>
      <div className="flex-1 bg-gray-200 ml-0.5"></div>
      <div className="flex-1 bg-gray-200 ml-0.5"></div>
      <div className="flex-1 bg-gray-200 ml-0.5 rounded-r-full"></div>
    </div>
  </>
);



const TermsAndConditions = () => {
 
  const { darkMode } = useDarkMode();
 

  
  return (
    <div className="relative">
      <p className="text-xs text-gray-500 dark:text-white">
        By creating an account, you agree to the{" "}
        <span 
          className=" text-[#039EF2]"
        >
          Terms & Conditions
        </span>
        .
      </p>
    </div>
  );
};

const SubmitButton = () => (
  <Button
    type="submit"
    bgColor="bg-gradient-to-r from-[#027EC2] to-[#039EF2]"
    textColor="text-white"
    className="w-full h-10"
    borderRadius="rounded-lg"
  >
    Complete Sign Up
  </Button>
);

const FooterLinks = ({ onSignIn }: { onSignIn: () => void }) => (
  <p className="text-center dark:text-white text-xs">
    Already have an account?{" "}
    <span
      className="text-[#039EF2] font-bold cursor-pointer"
      onClick={onSignIn}
    >
      Sign In
    </span>
  </p>
);
