// redux/store.ts: sets up the store and configures redux-persist.

import { configureStore } from "@reduxjs/toolkit";
import postsReducer from "../features/posts/store/postsSlice";
import { persistStore, persistReducer } from "redux-persist"; //persistReducer and persistStore for state persistence.
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./index"; // Adjust the path to your root reducer
import courseReducer from "../features/courseSlice";

const persistConfig = {
  key: "root", // Key for the persisted state.
  storage, // Storage engine (localStorage for web).
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
    posts: postsReducer, // Additional slices can be added here.
    courses: courseReducer,
  },
});

export const persistor = persistStore(store); //It ensures that the parts of the store wrapped with the persistReducer (in this case, persistedReducer) are persisted to the specified storage (e.g., localStorage).

// get back to these
export type RootState = ReturnType<typeof store.getState>; //RootState is the type for your entire store's state.
export type AppDispatch = typeof store.dispatch; //AppDispatch is the type for your store's dispatch function, ensuring type-safe action dispatching.
