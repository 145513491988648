// src/routes/AppRouter.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "../pages/Homepage";
import ViewAll from "../pages/ViewAll";
import SignIn from "../pages/auth/SignIn";
import Signup from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/view-all" element={<ViewAll />} />
        {/* <Route path="/login" component={LoginPage} />
        <Route component={NotFoundPage} /> */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
