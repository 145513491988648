const MostPopularToday = {
  header: {
    title: "Most Popular Today",
    img: "/PNGs/MostPopularToday.png",
    viewAll: false,
  },
  card: [
    {
      title: "Call of Duty Mobile",
      discount: "15% Off",
      image: "/images/COD.png",
    },
    {
      title: "PUBG Mobile UC",
      discount: "15% Off",
      image: "/images/PUBG.jpeg",
    },
    {
      title: "Apple Gift Cards",
      discount: "15% Off",
      image: "/images/AppleGiftCard.jpeg",
    },
    {
      title: "DATAUP Coins",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
    {
      title: "Farlight 84",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
  ],
};

const GameTopUp = {
  header: {
    title: "Direct Game Top-Up",
    img: "/PNGs/DirectGameTopUp.png",
    viewAll: false,
  },
  card: [
    {
      title: "Call of Duty Mobile",
      discount: "15% Off",
      image: "/images/COD.png",
    },
    {
      title: "PUBG Mobile UC",
      discount: "15% Off",
      image: "/images/PUBG.jpeg",
    },
    {
      title: "Apple Gift Cards",
      discount: "15% Off",
      image: "/images/AppleGiftCard.jpeg",
    },
    {
      title: "DATAUP Coins",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
    {
      title: "Farlight 84",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
  ],
};

const GameCards = {
  header: {
    title: "Game Cards",
    img: "/PNGs/GameCards.png",
    viewAll: true,
  },
  card: [
    {
      title: "Call of Duty Mobile",
      discount: "15% Off",
      image: "/images/COD.png",
    },
    {
      title: "PUBG Mobile UC",
      discount: "15% Off",
      image: "/images/PUBG.jpeg",
    },
    {
      title: "Apple Gift Cards",
      discount: "15% Off",
      image: "/images/AppleGiftCard.jpeg",
    },
    {
      title: "DATAUP Coins",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
    {
      title: "Farlight 84",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
  ],
};

const GiftCards = {
  header: {
    title: "Gift Cards",
    img: "/PNGs/GiftCards.png",
    viewAll: true,
  },
  card: [
    {
      title: "Call of Duty Mobile",
      discount: "15% Off",
      image: "/images/COD.png",
    },
    {
      title: "PUBG Mobile UC",
      discount: "15% Off",
      image: "/images/PUBG.jpeg",
    },
    {
      title: "Apple Gift Cards",
      discount: "15% Off",
      image: "/images/AppleGiftCard.jpeg",
    },
    {
      title: "DATAUP Coins",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
    {
      title: "Farlight 84",
      discount: "15% Off",
      image: "/images/DataUp.png",
    },
  ],
};

const PrepaidServices = {
  header: {
    title: "Prepaid Services",
    img: "/PNGs/PrepaidServices.png",
    viewAll: true,
  },
  card: [
    {
      title: "MTN",
      discount: "15% Off",
      image: "/images/mtn.png",
    },
    {
      title: "9mobile",
      discount: "15% Off",
      image: "/images/9mobile.png",
    },
    {
      title: "Globacom",
      discount: "15% Off",
      image: "/images/glo.png",
    },
    {
      title: "Airtel",
      discount: "15% Off",
      image: "/images/airtel.png",
    },
  ],
};

const ElectricityPayment = {
  header: {
    title: "Electricity Payment",
    img: "/PNGs/ElectricityPayment.png",
    viewAll: true,
  },
  card: [
    {
      title: "Abuja Electric Distribution Company",
      discount: "15% Off",
      image: "/images/AEDC.png",
    },
    {
      title: "Eko Electric Distribution Company",
      discount: "15% Off",
      image: "/images/EKEDC.png",
    },
    {
      title: "Enugu Electric Distribution Company",
      discount: "15% Off",
      image: "/images/EEDC.png",
    },
    {
      title: "Ibadan Electric Distribution Company",
      discount: "15% Off",
      image: "/images/IBEDC.png",
    },
    {
      title: "Ikeja Electric Distribution Company",
      discount: "15% Off",
      image: "/images/IEDC.png",
    },
  ],
};

const CableTV = {
  header: {
    title: "Cable TV",
    img: "/PNGs/CableTV.png",
    viewAll: true,
  },
  card: [
    {
      title: "DSTV",
      discount: "15% Off",
      image: "/images/dstv.png",
    },
    {
      title: "StarTimes",
      discount: "15% Off",
      image: "/images/startimes.png",
    },
    {
      title: "Gotv",
      discount: "15% Off",
      image: "/images/gotv.png",
    },
  ],
};

const mainContent = [
  MostPopularToday,
  GameTopUp,
  GameCards,
  GiftCards,
  PrepaidServices,
  ElectricityPayment,
  CableTV,
];

export default mainContent;

export const leaderboardData = [
  {
    title: "DarkLordNathan",
    point: 2000,
    path: "/PNGs/LB1.png",
  },
  {
    title: "BorgCollective",
    point: 1000,
    path: "/PNGs/LB2.png",
  },
  {
    title: "Bulletz4Breakfast",
    point: 3000,
    path: "/PNGs/LB3.png",
  },
  {
    title: "TheFinalCountdown",
    point: 1500,
    path: "/PNGs/LB4.png",
  },
  {
    title: "IronMAN77	",
    point: 4500,
    path: "/PNGs/LB5.png",
  },
];
