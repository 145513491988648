// src/api/auth.ts
import axios from "axios";

const API_URL = "https://api.datanow.ng/api/user/937506"; // replace with your API endpoint

export interface LoginResponse {
  token: string;
  data: {
    id: string;
    name: string;
    email: string;
  };
}

// you may use async/await or .then() syntax for promises
export const login = async (
  email: string,
  password: string
): Promise<LoginResponse> => {
  const response = await axios.post<LoginResponse>(`${API_URL}/signin`, {
    identifier: email,
    password,
  });
  console.log(response.data);
  return response.data;
};
