import React, { useState } from "react";
import Banners from "../../assets/images/banner3.png";
import Banners1 from "../../assets/images/banner2.png";
import Banners2 from "../../assets/images/banner1.png";
import Left from "../../assets/images/Left.svg";
import Right from "../../assets/images/Right.svg";

const Hero: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const slides: string[] = [Banners, Banners1, Banners2];

  const nextSlide = (): void => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = (): void => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index: number): void => {
    setActiveSlide(index);
  };

  return (
    <div className="relative w-full overflow-hidden" data-carousel="static">
      {/* Slider Container */}
      <div className="relative h-60 md:h-26 lg:h-[334px] w-full">
        {slides.map((slide: string, index: number) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
              index === activeSlide ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={slide}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover object-bottom"
            />
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <button
        type="button"
        className="absolute top-1/2 left-4 z-30 flex items-center justify-center transform -translate-y-1/2 cursor-pointer group focus:outline-none"
        onClick={prevSlide}
      >
        <img src={Left} alt="Previous" className="w-4 h-4 lg:w-10 lg:h-10" />
      </button>
      <button
        type="button"
        className="absolute top-1/2 right-4 z-30 flex items-center justify-center transform -translate-y-1/2 cursor-pointer group focus:outline-none"
        onClick={nextSlide}
      >
        <img src={Right} alt="Next" className="w-4 h-4 lg:w-10 lg:h-10" />
      </button>

      {/* Indicators */}
      <div className="flex justify-center items-center py-4">
        {slides.map((_: string, index: number) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-1 rounded-sm mx-2 transition-all duration-300 ${
              index === activeSlide
                ? "bg-blue-500 scale-125"
                : "bg-gray-300 hover:scale-125"
            }`}
            aria-current={index === activeSlide}
            aria-label={`Slide ${index + 1}`}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Hero;
