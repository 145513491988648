import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const URL = "http://localhost:8000/interview/courses/";

export interface Course {
  id: number;
  title: string;
}

export const fetchCourses = async (): Promise<Course[]> => {
  const response = await axios.get<Course[]>(URL);
  console.log(response.data);
  return response.data;
};

export const getCourse = createAsyncThunk<
  Course[],
  void,
  { rejectValue: string }
>("courses/getCourse", async (_, thunkAPI) => {
  try {
    const response = await fetchCourses();
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface CourseState {
  courses: Course[];
  loading: boolean;
  error: string | null;
}

const initialState: CourseState = {
  courses: [],
  loading: false,
  error: null,
};

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload;
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default courseSlice.reducer;
